<template>
  <div class="main p-35">
    <div class="cut_off p-b-10">
      <p class="font24 color-ffa">我的信息</p>
    </div>
    <div class="co_box m-t-20 flex p-40">
      <div class="font16 color333 text-r l-h60 weight">
        <div>昵称:</div>
        <div>单位名称:</div>
        <div>手机号:</div>
      </div>
      <div class="text-l font16 color333 m-l-30 l-h60">
        <div>{{user.user_name}}</div>
        <div>{{user.name}}</div>
        <div>{{user.phone}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        user:{}
    };
  },
  components: {},
  mounted() {
    let user = localStorage.getItem("userInfo");
    this.user = JSON.parse(user);
  },
  methods:{

  }
};
</script>

<style lang="scss" scoped>
.main {
  height: 561px;
  background: #ffffff;
  .cut_off {
    border-bottom: 1px solid #ffa54b;
  }
  .co_box {
    width: 859px;
    height: 260px;
    border-radius: 10px;
    border: 1px solid #e2e2e2;
  }
}
</style>